<template>
    <li :id="'view-list-tree-node-' + id" 
        class="view-list-tree-node">
        <div v-if="!isLink">
            <button 
                class="nav-item nav-item-menu d-flex align-items-left btn btn-toggle rounded w-100"
                :class="visible ? null : 'collapsed'"
                :aria-expanded="visible ? 'true' : 'false'"
                :aria-controls="'#collapse-' + id"
                @click="visible = !visible"
            >
                <b-icon-chevron-right shift-v="2" class="nav-item-menu-icon nav-item-menu-icon-closed me-1"></b-icon-chevron-right>
                <b-icon-chevron-down shift-v="2" class="nav-item-menu-icon nav-item-menu-icon-open me-1"></b-icon-chevron-down>
                <span>{{label}}</span>
            </button>
            <b-collapse class="ps-3" :id="'collapse-' + id" v-model="visible">
                <view-list-tree-menu 
                    v-for="node in nodes"
                    v-bind:key="node.id"
                    :id="node.id"
                    :nodes="node.children"
                    :label="node.name"
                    :url="node.url || false"
                    :is-favorite="node.isFavorite"
                    :is-link="node.project"
                    :view="node">
                </view-list-tree-menu>
            </b-collapse>
        </div>
        <div v-else class="nav-item d-flex flex-row w-100">
            <a class="nav-link flex-grow-1 text-truncate"
                href="#"
                v-on:click.prevent="onViewClick(view)"
                v-bind:class="{ active: isSelected }">{{label}}</a>
            <div class="z-favorite-icon py-1 me-3 h4 text-warning" v-on:click.stop="onFavoriteClick(view)">
                <b-icon-star-fill v-if="isFavorite" v-bind:variant="favoriteIconVariant"></b-icon-star-fill>
                <b-icon-star v-else v-bind:variant="favoriteIconVariant" class="star"></b-icon-star>
            </div>
        </div>
    </li>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { BIconStar, BIconStarFill, BIconChevronDown, BIconChevronRight, BCollapse } from 'bootstrap-vue';

/**
 * Displays a nested tree menu of items.
 * @tag z-view-list-tree-menu
 * @ignore
 */
export default { 
    props: [ 'nodes', 'label', 'id', 'url', 'isLink', 'isFavorite', 'view' ],
    name: 'ViewListTreeMenu',
    components: {
        BIconStar,
        BIconStarFill,
        BIconChevronDown,
        BIconChevronRight,
        BCollapse
    },
    data(){
        return {
            visible: false
        };
    },
    computed: {
        ...mapState('views', {
            currentView: (state) => state.view,
        }),
        isSelected() {
            return this.currentView?.url === this.url;
        },
        isContainSelected(){
            return this.nodes?.some(node => this.currentView?.url === node.url);
        }
    },
    created(){
        if (this.isContainSelected) {
            this.visible = true;
        }
    },
    mounted(){
        if (this.isSelected) {
            this.$el.scrollIntoView({behavior: "smooth"})
        }
    },
    methods: {
        ...mapActions('views', [
            'setView',
            'addFavorite',
            'removeFavorite',
        ]),
        onViewClick: function onViewClick(view) {
            this.setView(view);
        },
        onFavoriteClick: function (view) {
            if (view.isFavorite) {
                this.removeFavorite(view);
            } else {
                this.addFavorite(view);
            }
            view.isFavorite = !view.isFavorite;
        }
    }
}
</script>
<style scoped>
.nav-item-menu-icon {
    display: none;
}
.nav-item-menu[aria-expanded="false"] .nav-item-menu-icon-closed {
    display: flex;
}
.nav-item-menu[aria-expanded="true"] .nav-item-menu-icon-open {
    display: flex;
}
</style>