<template>
    <input
        type="text"
        class="form-control"
        name="tableau_server_url"
        v-model="url"
        v-on:input="onValueChange($event)"
        v-on:keyup.enter="login"
        v-bind:placeholder="label"
        v-bind:class="myClass" />
</template>

<script>
import store from '../store/store';
import { mapActions, mapMutations } from 'vuex';
/**
 * Component displays input that allow user to enter Tableau's Server Url.
 * @tag z-login-tableau-server-url-input
 */
export default {
    name: 'LoginTableauServerUrlInput',
    store,
    props: {
        /**
         * Makes input required.
         * @example required
         */
        required: {
            type: Boolean,
            default: false
        },
        /**
         * Text will be displayed as input's placeholder.
         * @example label="Example"
         */
        label: {
            type: String,
            default: 'Tableau Server URL'
        },
        /**
         * Adds class to input element.
         * @example my-class="Example"
         */
        myClass: {
            type: String
        }
    },
    data: function () {
        return {
            url: ''
        };
    },
    created() {
        if (this.required) {
            store.commit('login/required', 'tableauServerUrl');
        }
    },
    methods: {
        ...mapActions('login', [ 'login' ]),
        ...mapMutations('login', {
            setLoginError:'error',
            setTableauServerUrl:'tableauServerUrl',
        }),
        onValueChange: function () {
            this.setLoginError(null);
            this.setTableauServerUrl(this.url);
        }
    }
};
</script>

<style>
</style>
