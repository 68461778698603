import Vue from 'Vue';
import {VBTooltipPlugin} from 'bootstrap-vue';
import vueCustomElement from 'vue-custom-element'

import BackgroundVisual from './components/BackgroundVisual.vue';
import Customizer from './components/Customizer.vue';
import FileUpload from './components/FileUpload.vue';
import LoginError from './components/LoginError.vue';
import LoginPasswordInput from './components/LoginPasswordInput.vue';
import LoginSubmitButton from './components/LoginSubmitButton.vue';
import LoginTableauServerSiteInput from './components/LoginTableauServerSiteInput.vue';
import LoginTableauServerUrlInput from './components/LoginTableauServerUrlInput.vue';
import LoginUsernameInput from './components/LoginUsernameInput.vue';
import Logo from './components/Logo.vue';
import NavDownloadButton from './components/NavDownloadButton.vue';
import NavFullscreenButton from './components/NavFullscreenButton.vue';
import NavDownloadDialogButton from './components/NavDownloadDialogButton.vue';
import NavPlayPauseButton from './components/NavPlayPauseButton.vue';
import NavRedoButton from './components/NavRedoButton.vue';
import NavRefreshButton from './components/NavRefreshButton.vue';
import NavRevertButton from './components/NavRevertButton.vue';
import NavShareButton from './components/NavShareButton.vue';
import NavUndoButton from './components/NavUndoButton.vue';
import NavUserMenu from './components/NavUserMenu.vue';
import PasswordEditModal from './components/PasswordEditModal.vue';
import TableauViz from './components/TableauViz.vue';
import UserEditModal from './components/UserEditModal.vue';
import ViewList from './components/ViewList.vue';
import WorkbookList from './components/WorkbookList.vue';

Vue.use(vueCustomElement);
Vue.use(VBTooltipPlugin);

Vue.customElement('z-background-visual', BackgroundVisual);
Vue.customElement('z-customizer', Customizer);
Vue.customElement('z-file-upload', FileUpload);
Vue.customElement('z-login-error', LoginError);
Vue.customElement('z-login-password-input', LoginPasswordInput);
Vue.customElement('z-login-submit-button', LoginSubmitButton);
Vue.customElement('z-login-tableau-server-site-input', LoginTableauServerSiteInput);
Vue.customElement('z-login-tableau-server-url-input', LoginTableauServerUrlInput);
Vue.customElement('z-login-username-input', LoginUsernameInput);
Vue.customElement('z-logo', Logo);
Vue.customElement('z-nav-download-button', NavDownloadButton);
Vue.customElement('z-nav-download-dialog-button', NavDownloadDialogButton);
Vue.customElement('z-nav-fullscreen-button', NavFullscreenButton);
Vue.customElement('z-nav-play-pause-button', NavPlayPauseButton);
Vue.customElement('z-nav-redo-button', NavRedoButton);
Vue.customElement('z-nav-refresh-button', NavRefreshButton);
Vue.customElement('z-nav-revert-button', NavRevertButton);
Vue.customElement('z-nav-share-button', NavShareButton);
Vue.customElement('z-nav-undo-button', NavUndoButton);
Vue.customElement('z-nav-user-menu', NavUserMenu);
Vue.customElement('z-password-edit-modal', PasswordEditModal);
Vue.customElement('z-tableau-viz', TableauViz);
Vue.customElement('z-user-edit-modal', UserEditModal);
Vue.customElement('z-view-list', ViewList);
Vue.customElement('z-workbook-list', WorkbookList);
