<template>
    <li :id="'workbook-list-tree-node-' + id" 
        class="workbook-list-tree-node">
        <div v-if="!isLink">
            <button 
                class="nav-item nav-item-menu d-flex align-items-left btn btn-toggle rounded collapsed w-100"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse-' + id"
                aria-expanded="false">
                <b-icon-chevron-right shift-v="2" class="nav-item-menu-icon nav-item-menu-icon-closed me-1"></b-icon-chevron-right>
                <b-icon-chevron-down shift-v="2" class="nav-item-menu-icon nav-item-menu-icon-open me-1"></b-icon-chevron-down>
                <span>{{label}}</span>
            </button>
            <div class="collapse ps-3 " :id="'collapse-' + id">
                <workbook-list-tree-menu 
                    v-for="node in nodes"
                    v-bind:key="node.id"
                    :id="node.id"
                    :nodes="node.children"
                    :label="node.name"
                    :url="node.url || false"
                    :is-favorite="node.isFavorite"
                    :is-link="!!node.project"
                    :workbook="node">
                </workbook-list-tree-menu>
            </div>
        </div>
        <div v-else class="nav-item d-flex flex-row w-100">
            <a class="nav-link flex-grow-1 text-truncate"
                href="#"
                v-on:click.prevent="onWorkbookClick(workbook)"
                v-bind:class="{ active: currentWorkbook && currentWorkbook.id === id }">{{label}}</a>
            <div class="z-favorite-icon py-1 me-3 h4 text-warning" v-on:click.stop="onFavoriteClick(workbook)">
                <b-icon-star-fill v-if="isFavorite" v-bind:variant="favoriteIconVariant"></b-icon-star-fill>
                <b-icon-star v-else v-bind:variant="favoriteIconVariant" class="star"></b-icon-star>
            </div>
        </div>
    </li>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { BIconStar, BIconStarFill, BIconChevronDown, BIconChevronRight } from 'bootstrap-vue';

/**
 * Component displays list of workbooks.
 * @tag z-workbook-list-tree-menu 
 * @ignore
 */
export default {
    name: 'WorkbookListTreeMenu',
    props: [ 'nodes', 'label', 'id', 'url', 'isLink', 'isFavorite', 'workbook' ],
    components: {
        BIconStar,
        BIconStarFill,
        BIconChevronDown,
        BIconChevronRight
    },
    computed: {
        ...mapState('workbooks', {
            currentWorkbook: (state) => state.workbook,
        })
    },
    methods: {
        ...mapActions('workbooks', [
            'setWorkbook',
            'addFavorite',
            'removeFavorite'
        ]),
        onWorkbookClick(workbook) {
            this.setWorkbook({workbook});
        },
        onFavoriteClick: function (workbook) {
            if (workbook.isFavorite) {
                this.removeFavorite(workbook);
            } else {
                this.addFavorite(workbook);
            }
            workbook.isFavorite = !workbook.isFavorite;
        },
    }
};
</script>

<style>
    .nav-item-menu-icon {
        display: none;
    }
    .nav-item-menu[aria-expanded="false"] .nav-item-menu-icon-closed {
        display: flex;
    }
    .nav-item-menu[aria-expanded="true"] .nav-item-menu-icon-open {
        display: flex;
    }
    .z-dropdown .dropdown-toggle::after{
        display: none;
    }
</style>
