<template>
    <div class="z-nav-item">
        <b-dropdown v-b-tooltip.hover="{customClass: 'opacity-100' }" title="Share" right v-bind:variant="variant" v-if="viz">
            <template #button-content>
                <b-icon-share data-toggle="dropdown"></b-icon-share>
            </template>
            <b-dropdown-text class="user-select-all pb-0" test-id="share">{{shareUrl}}</b-dropdown-text>
        </b-dropdown>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '../store/store';
import { BDropdown, BDropdownText, BIconShare } from 'bootstrap-vue';
/**
 * Component displays button that open dropdown with share link.
 * @tag z-nav-share-button
 */
export default {
    name:'NavShareButton',
    store,
    components: {
        BDropdown,
        BDropdownText,
        BIconShare,
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', [ 'viz' ]),
        ...mapState('views', [ 'view' ]),
        shareUrl: function () {
            return this.view ? `${this.view.url}?:embed=y` : '';
        },
    },
};
</script>

<style>
.b-dropdown-text {
     margin-bottom: 0;
}
.opacity-100{
    opacity: 1;
}
</style>
