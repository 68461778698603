<template>
    <div class="z-nav-item">
        <button v-b-tooltip.hover="{customClass: 'opacity-100' }" title="Fullscreen" class="btn" v-bind:class="'btn-'+variant" v-on:click="enableFullscreen()" v-if="viz">
            <b-icon-fullscreen v-if="!isFullscreen"></b-icon-fullscreen>
            <b-icon-fullscreen-exit v-else></b-icon-fullscreen-exit>
        </button>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import store from '../store/store';
import { BIconFullscreen, BIconFullscreenExit } from 'bootstrap-vue';
/**
 * Component displays button that open current Tableau Viz in fullsreen mode.
 * @tag z-nav-fullscreen-button
 */
export default {
    name:'NavFullscreenButton',
    store,
    components: {
        BIconFullscreen,
        BIconFullscreenExit
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', ['viz']),
        ...mapState(['isFullscreen']),
    },
    methods: {
        ...mapMutations({
            setIsFullscreen: 'isFullscreen'
        }),
        enableFullscreen(){
            this.setIsFullscreen(true);
            var element = document.getElementById('vizContainer');
            if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            }
        },
        onFullScreenChange() {
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                this.setIsFullscreen(false);
            }
        }
    },
    created () {
        document.addEventListener("fullscreenchange", this.onFullScreenChange, false);
        document.addEventListener("webkitfullscreenchange", this.onFullScreenChange, false);
        document.addEventListener("mozfullscreenchange", this.onFullScreenChange, false);
    },
    destroyed() {
        document.removeEventListener("fullscreenchange", this.onFullScreenChange, false);
        document.removeEventListener("webkitfullscreenchange", this.onFullScreenChange, false);
        document.removeEventListener("mozfullscreenchange", this.onFullScreenChange, false);
    }
};
</script>

<style>
.opacity-100{
    opacity: 1;
}
</style>
