import * as api from '../../services/api.service';
import * as util from '../../services/util.service';

const state = () => ({
    loadingProjects: true,
    projects: []
});

const mutations = {
    loadingProjects (state, isLoading) {
        state.loadingProjects = isLoading;
    },
    projects (state, projects) {
        state.projects = projects;
        state.projects.sort(util.projectSort);
    }
};

const getters = {};

const actions = {
    loadProjects ({commit}) {
        commit('loadingProjects', true);
        return api.getProjects().then((projects) => {
            commit('projects', projects);
            commit('loadingProjects', false);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};