<template>
    <img v-bind:src="logo" v-bind:height="height" v-bind:width="width" />
</template>

<script>
import { mapState } from "vuex";
import store from "../store/store";
/**
 * Component displays image that was selected as logo in Customizer component.
 * @tag z-logo
 */
export default {
    name: "Logo",
    store,
    props: {
        /**
         * Width of the image.
         * @example width="100"
         */
        width: {
            type: Number,
        },
        /**
         * Height of the image.
         * @example height="100"
         */
        height: {
            type: Number,
        },
    },
    computed: {
        ...mapState('customizer', ["logo"]),
    },
};
</script>

<style>
    z-logo {
        display: block;
    }
</style>
