<template>
    <div class="z-nav-item">
        <button v-b-tooltip.hover="{customClass: 'opacity-100' }" title="Undo" class="btn" v-bind:class="'btn-'+variant" v-on:click="undo()" v-if="viz">
            <b-icon-arrow-left></b-icon-arrow-left>
        </button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BIconArrowLeft } from 'bootstrap-vue';
/**
 * Component displays button that undo Tableau Viz action. 
 * @tag z-nav-undo-button
 */
export default {
    name: 'NavUndoButton',
    store,
    components: {
        BIconArrowLeft,
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', ['viz']),
    },
    methods: {
        ...mapActions('viz', [
            'undo',
        ]),
    },
};
</script>

<style>
.opacity-100{
    opacity: 1;
}
</style>
