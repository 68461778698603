function projectSort (a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function viewSort (a, b) {
    if (a.isFavorite && !b.isFavorite) {
        return -1;
    }
    if (b.isFavorite && !a.isFavorite) {
        return 1;
    }

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function generateProjectTree (item, data) {
    if (item.isView || item.isWorkbook) {
        return item;
    }
    const childViews = item.children || [];
    const childProjects = data
        .filter(d => d.parentProjectId === item.id) // Only views/projects that are in this project
        .map(d => generateProjectTree(d, data)) // Generate subtree
        .filter(d => d.isView || d.isWorkbook || d.children); // Only views and projects that have children
    const children = [...childViews, ...childProjects].sort(viewSort);
    return children.length ? { ...item, children } : item;
}

function removeDuplicates (array) {
    for (let i = array.length - 1; i >= 0; i--) {
        let otherInstanceIndex = array.findIndex(function (el) {
            return el.id === array[i].id;
        });
        if (otherInstanceIndex !== i && otherInstanceIndex !== -1) {
            array.splice(i, 1);
        }
    }
}

export {
    projectSort,
    viewSort,
    generateProjectTree,
    removeDuplicates
};