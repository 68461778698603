<template>
    <div class="z-nav-item">
        <button
            v-b-tooltip.hover="{customClass: 'opacity-100' }"
            title="Revert"
            class="btn"
            v-bind:class="'btn-'+variant"
            v-on:click="revert()"
            v-if="viz"
        >
            <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
        </button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BIconArrowCounterclockwise } from 'bootstrap-vue';
/**
 * Component displays button that revert Tableau Viz action. 
 * @tag z-nav-revert-button
 */
export default {
    name:'NavRevertButton',
    store,
    components: {
        BIconArrowCounterclockwise,
    },
    props: {
        /**
         * Text will be displayed as input's placeholder.
         * @example label="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('viz', [ 'viz' ]),
    },
    methods: {
        ...mapActions('viz', [
            'revert'
        ]),
    },
};
</script>

<style>
.opacity-100{
    opacity: 1;
}
</style>
