<template>
    <div class="modal fade" ref="modal" id="password-edit-modal" tabindex="-1" role="dialog" aria-labelledby="updatePasswordModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="updatePasswordModalLabel">Change Password</h5>
                    <button v-on:click="onCloseClick()" type="button" class="btn close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating mb-3">
                            <input
                                type="password"
                                autocomplete="password"
                                class="form-control"
                                id="currentPassword"
                                name="password"
                                placeholder="Current Password"
                                v-model="currentPassword"
                                v-on:keyup="onInputChange()"
                                v-on:change="onInputChange()">
                            <label for="currentPassword" class="form-label">Current Password</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="password"
                                autocomplete="new-password"
                                class="form-control"
                                id="updatePassword1"
                                placeholder="New Password"
                                v-model="newPassword"
                                v-on:keyup="onInputChange()"
                                v-on:change="onInputChange()">
                            <label for="updatePassword1" class="form-label">New Password</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="password"
                                autocomplete="new-password"
                                class="form-control"
                                id="updatePassword2"
                                placeholder="Confirm Password"
                                v-model="newPasswordConfirm"
                                v-on:keyup="onInputChange()"
                                v-on:change="onInputChange()">
                            <label for="updatePassword2" class="form-label">Confirm Password</label>
                        </div>
                    </form>

                    <div v-if="showError" class="alert alert-warning hidden update-password-error" role="alert" test-id="updating-error">
                        There was an error updating your password.
                    </div>

                    <div v-if="showNowAllowed" class="alert alert-warning hidden update-password-error" role="alert" test-id="not-allowed-error">
                        Password change not allowed for site admin.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="onCloseClick()">Cancel</button>
                    <button
                        type="button"
                        id="updatePasswordSaveButton"
                        class="btn btn-primary"
                        :disabled="submitDisabled"
                        v-on:click="onSaveClick()"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import store from '../store/store';
/**
 * Component renders modal that allow user change password. Modal might be opened with "Change password" option in <a href="#NavUserMenu">NavUserMenu</a>
 * </br><strong>Note!</strong> This component is required to "Change password" option appear in <a href="#NavUserMenu">NavUserMenu</a> component.
 * @tag z-password-edit-modal
 */
export default {
    name: 'PasswordEditModal',
    store,
    created () {
        this.passwordEditModalExists(true);
    },
    data: function () {
        return {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            showError: false,
            showNowAllowed: false,
        };
    },
    computed: {
        submitDisabled: function () {
            return !this.newPassword.length || !this.newPasswordConfirm.length || this.newPassword !== this.newPasswordConfirm;
        }
    },
    methods: {
        ...mapActions('user', [
            'togglePasswordEditModal',
            'updatePassword'
        ]),
        ...mapMutations('user', [
            'passwordEditModalExists'
        ]),
        onInputChange () {
            this.showError = false;
        },
        onCloseClick: function () {
            this.togglePasswordEditModal();
            this.reset();
        },
        onSaveClick () {
            this.showError = false;
            this.updatePassword({currentPassword: this.currentPassword, newPassword: this.newPassword})
                .then(this.onCloseClick)
                .catch((res) => {
                    if (res.message.includes('409')) {
                        this.showNowAllowed = true;
                    } else {
                        this.showError = true;
                    }
                });
        },
        reset () {
            this.currentPassword = '';
            this.newPassword = '';
            this.newPasswordConfirm = '';
            this.showError = false;
            this.showNowAllowed = false;
        }
    }
};
</script>

<style lang="scss">
.password-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, .4);
    transition: all 0.3s;
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .modal {
        display: block;
    }
    .modal-dialog {
        max-width: 500px;
        position: relative;
        margin: 1.75rem auto;
        transition: transform .3s ease-out;
    }
    .modal-content {
        height: auto;
        background: #ffffff;
        pointer-events: all;
    }

    .form-control:focus {
        outline: 0;
    }
    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
