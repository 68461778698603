import axios from 'axios';


// Axios Settings 
axios.defaults.withCredentials = true;

/* eslint-disable */
const PAGE_SIZE = 99;
const BASE_URL = '__API_URL__' || window.location.origin; // __API_URL__ from .env and replaced at buildtime

var state = {
    about: {},
    BASE_URL: BASE_URL,
};

const get = (url, options = {}) => {
    return axios({
        method: 'get',
        url,
        withCredentials: true,
        crossDomain: true,
        ...options
    });
};
const patch = (url, payload = {}, options = {}) => {
    return axios({
        method: 'patch',
        url,
        data: payload,
        withCredentials: true,
        crossDomain: true,
        ...options
    });
};
const post = (url, payload = {}, options = {}) => {
    return axios({
        method: 'post',
        url,
        data: payload,
        withCredentials: true,
        crossDomain: true,
        ...options
    });
};
const put = (url, payload = {}, options = {}) => {
    return axios({
        method: 'put',
        url,
        data: payload,
        withCredentials: true,
        ...options
    });
};
const del = (url, options = {}) => {
    return axios({
        method: 'delete',
        url,
        ...options
    });
};

const init = (() => {
    let promise = null;
    return () => {
        promise = promise || get(state.BASE_URL + '/auth/about', { responseType: 'json' }).then((response) => {
            state.about = response.data;
            state.BASE_URL = `${state.BASE_URL}/api/${state.about.tableau_server.rest_api_version}`;
            return state.about;
        });
        return promise;
    };
})();

const getFavorites = async (
    entity // view, workbook
) => {
    await init();
    const url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/favorites/${state.about.payload.tableau_site_user_id}`;
    let response = await get(url);
    if (response.status !== 200) {
        console.error('Error getting favorites', response);
        return []
    }
    let result = response.data.favorites.favorite.filter(fav => fav[entity]);
    return result;
}

const buildViewUrl = (contentUrl) => {
    const siteContentUrl = state.about.tableau_server.site_content_url;
    const newContentUrl = contentUrl.replace(/\/sheets\//, '/');

    let url = `/views/${newContentUrl}`;
    if (siteContentUrl !== '' && siteContentUrl !== 'default' && siteContentUrl !== null) {
        url = `/t/${siteContentUrl}${url}`;
    }
    url = BASE_URL + url;
    return url;
};

const getWorkbooks = async (
    { tags = [] } = {},
    pageNumber = 1,
    pageSize = PAGE_SIZE,
    result = []
) => {
    await init();
    let url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/workbooks?sort=name:asc&pageSize=${pageSize}&pageNumber=${pageNumber}`;

    // Add tags filter
    let compactTags = tags.filter(tag => tag);
    if (compactTags.length) {
        url += `&filter=tags:in:[${compactTags.join(',')}]`;
    }
    // Get favorites workbooks
    let favorites = await getFavorites('workbook');
    let favoriteWorkbookIds = favorites.map(workbook => workbook.id);

    // Request
    let response = await get(url);
    const { workbooks, pagination } = response.data;
    workbooks.workbook.forEach((workbook) => {
        workbook.isFavorite = favoriteWorkbookIds.includes(workbook.id);
        workbook.isWorkbook = true; // Differentiate between workbooks and projects
        workbook.parentProjectId = workbook?.project.id || null;
        workbook.showTabs = workbook.showTabs === 'true'; // Cast string boolean to actual boolean
        result.push(workbook);
    });
    return { workbooks: result, pagination };
}

const getViewsForWorkbook = async ({ workbook }) => {
    await init();
    let views = [];
    let url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/workbooks/${workbook.id}/views`;
    let repsponse = await get(url);
    views = repsponse.data.views.view.map(view => {
        view.url = buildViewUrl(view.contentUrl);
        return view;
    })
    return views;
}

const addWorkbookToFavorite = async ({ workbook }) => {
    await init();
    const site_id = state.about.tableau_server.site_id,
        tableau_site_user_id = state.about.payload.tableau_site_user_id;
    const url = `${state.BASE_URL}/sites/${site_id}/favorites/${tableau_site_user_id}`;
    const payload = { favorite: { label: workbook.id, workbook: { id: workbook.id } } };

    let response = await put(url, payload);

    if (response.status !== 200) {
        console.error('Error response', response);
        return Promise.reject(response);
    }
    return response;
}
const deleteWorkbookFromFavorite = async ({ workbook }) => {
    await init();
    const site_id = state.about.tableau_server.site_id,
        tableau_site_user_id = state.about.payload.tableau_site_user_id;
    const url = `${state.BASE_URL}/sites/${site_id}/favorites/${tableau_site_user_id}/workbooks/${workbook.id}`;

    let response = await del(url);

    if (response.status !== 200) {
        return Promise.reject(`${response.querySelector('summary').textContent}: ${response.querySelector('detail').textContent}`);
    }
    return response;
}
const getView = ({ name } = {}) => {
    return init().then(() => {
        const getViewUrl = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/views?filter=viewUrlName:eq:${name}`;
        return get(getViewUrl)
            .then(res => {
                const view = res.data.views.view[0];
                if (view) {
                    view.url = buildViewUrl(view.contentUrl);
                }
                return view || false;
            })
            .catch(e => {
                console.error(`Error getting view ${name}`, e);
            });
    });
};

const getViews = ({ workbook, owner, project, tags = [], sort = 'name:asc' } = {}, pageNumber = 1, pageSize = PAGE_SIZE, views = []) => {
    return init().then(() => {
        return getFavorites('view').then((favorites) => {
            let favoritesIdMap = favorites.map(view => view.id);
            let url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/views?sort=${sort}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
            tags = tags.filter(tag => tag.length); // Filter empty tags
            if (tags.length) {
                url += `&filter=tags:in:[${tags.join(',')}]`;
            }
            return get(url).then(function (res) {
                if (res.data.views.view) {
                    res.data.views.view.forEach(function (view) {
                        if (!views.find(v => v.id === view.id)) {
                            view.isView = true; // Differentiate between dashboards and projects
                            view.parentProjectId = view?.project.id || null;
                            view.url = buildViewUrl(view.contentUrl);
                            view.isFavorite = favoritesIdMap.includes(view.id);
                            view.previewImage = BASE_URL + '/api/' + state.about.tableau_server.rest_api_version + '/sites/' + state.about.tableau_server.site_id + '/workbooks/' + view.workbook.id + '/views/' + view.id + '/previewImage'
                        }
                    });
                    views.push(...res.data.views.view);
                }

                let pagination = res.data.pagination;
                return { views, pagination };
            })
                .catch(e => {
                    console.error('Error getting views', e);
                });
        });
    });
};

const addViewToFavorites = (viewId) => {
    const url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/favorites/${state.about.payload.tableau_site_user_id}`;
    const payload = {
        favorite: {
            label: viewId,
            view: {
                id: viewId
            }
        }
    };
    return new Promise((resolve, reject) => {
        put(url, payload)
            .then((response) => {
                resolve(response);
            })
            .catch((response) => {
                console.error('Error response', response);
                reject(`${response.summary}: ${response.detail}`);
            });
    });
};

const deleteViewFromFavorites = (viewId) => {
    const url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/favorites/${state.about.payload.tableau_site_user_id}/views/${viewId}`;
    return new Promise((resolve, reject) => {
        del(url, {})
            .then((response) => {
                resolve(response);
            })
            .catch((xmlResponseDoc) => {
                reject(`${xmlResponseDoc.querySelector('summary').textContent}: ${xmlResponseDoc.querySelector('detail').textContent}`);
            });
    });
};

const getProjects = () => init().then(() => {
    let url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/projects`;
    return get(url).then((response) => {
        response.data.projects.project.forEach(p => p.isProject = true);
        return response.data.projects.project;
    })
        .catch(e => {
            console.error('Error getting Projects', e);
        });;
});

const getUser = () => {
    return get('/auth/me').then(res => res.data);
}

const updateUser = ({ fullname, email }) => {
    return patch('/auth/me', { fullname, email });
}

const updatePassword = (currentPassword, newPassword) => {
    const uri = `/auth/passwd`;
    return post(uri, { 'old_password': currentPassword, 'new_password': newPassword });
};

const login = async (user, pass, tableauUrl, tableauSite) => {
    const url = window.location.search ? `/login${window.location.search}` : `/login`;
    const formData = new FormData();

    // Vault support
    const urlParams = new URLSearchParams(window.location.search);
    let loc = urlParams.get('location');
    if (loc) {
        formData.append('location', loc);
    }

    formData.append('username', user);
    formData.append('password', pass);
    if (tableauUrl) {
        formData.append('tableau_server_url', tableauUrl);
    }
    if (tableauSite) {
        formData.append('tableau_server_site', tableauSite);
    }
    const response = await post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

    return { response, location: loc || '/' }
};

const logout = () => {
    const form = document.createElement('form');
    form.style.visibility = 'hidden';
    form.method = 'POST';
    form.action = '/logout';
    document.body.appendChild(form);
    form.submit();
};

const getConfig = () => {
    return new Promise((resolve, reject) => {
        getLocalFile('/zconfig.json')
            .then(config => {
                if (typeof config === 'object') {
                    resolve(config);
                } else {
                    console.error('Error parsing zconfig.json', config);
                    resolve(config);
                }
            })
            .catch(e => {
                console.error('zconfig.json not found', e);
                resolve({});
            });
    });
};

const saveConfig = (config) => {
    const blob = new Blob([JSON.stringify(config, null, 4)], {
        type: 'text/plain'
    });
    const data = new FormData();
    data.append('file', blob, 'zconfig.json');
    return axios({
        method: 'POST',
        url: '/admin/edit/',
        data: data
    })
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('Error saving config', error);
        });
};

const getLocalFile = (filename) => {
    return get(filename)
        .then(res => {
            return res.data;
        });
};

const getAppTemplates = ({ repo, branch }) => {
    let url = `https://api.github.com/repos/${repo}/contents/app`;
    if (branch) {
        url = `${url}?ref=${branch}`;
    }
    return get(url, { withCredentials: false })
        .then(res => {
            return res.data;
        });
}

const getLoginTemplates = ({ repo, branch }) => {
    let url = `https://api.github.com/repos/${repo}/contents/login`;
    if (branch) {
        url = `${url}?ref=${branch}`;
    }
    return get(url, { withCredentials: false })
        .then(res => {
            return res.data;
        });
}

const getTemplateScreenshots = ({ repo }) => {
    let result = {}; // template.name: blob
    return get(`https://api.github.com/repos/${repo}/contents/screenshots`, { withCredentials: false })
        .then(res => {
            res.data.forEach(file => {
                let namePieces = file.name.split('.');
                namePieces.splice(namePieces.length - 1, 1);
                let templateName = namePieces.join('.');
                result[templateName] = file.download_url;
            });
            return result;
        });
};

const getTemplate = (templateUrl) => {
    return get(templateUrl, { withCredentials: false })
        .then(res => {
            return res.data;
        });
}

const saveTemplate = (contents, filename) => {
    const blob = new Blob([contents], {
        type: 'text/plain'
    });
    const data = new FormData();
    data.append('file', blob, filename);
    return axios({
        method: 'POST',
        url: '/admin/edit/',
        data: data
    })
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('Error saving template', error);
        });
}

const upload = (file) => {
    const data = new FormData();
    const uploadedFilename = `/assets/${file.name}`;
    data.append('file', file, uploadedFilename);
    return axios({
        method: 'POST',
        url: '/admin/edit/',
        data: data
    });
}

export {
    init,
    getConfig,
    getTemplate,
    getAppTemplates,
    getLoginTemplates,
    getTemplateScreenshots,
    getLocalFile,
    getWorkbooks,
    getViewsForWorkbook,
    addWorkbookToFavorite,
    deleteWorkbookFromFavorite,
    getView,
    getViews,
    getProjects,
    saveConfig,
    saveTemplate,
    addViewToFavorites,
    deleteViewFromFavorites,
    getUser,
    updateUser,
    updatePassword,
    upload,
    logout,
    login
};
