import * as api from '../../services/api.service';

const state = () => ({
    user: null,
    
    userEditModal: null,
    userEditModalExists: false,

    passwordEditModal: null,
    passwordEditModalExists: false,
});

const mutations = {
    user (state, user) {
        state.user = user;
    },
    userEditModal (state, modal) {
        state.userEditModal = modal;
    },
    userEditModalExists (state, modalExists) {
        state.userEditModalExists = modalExists;
    },
    passwordEditModal (state, modal) {
        state.passwordEditModal = modal;
    },
    passwordEditModalExists (state, modalExists) {
        state.passwordEditModalExists = modalExists;
    },
};

const getters = {};

const actions = {
    getUser ({commit}) {
        return api.getUser().then(user => commit('user', user));
    },
    updateUser ({}, user) {
        return api.updateUser(user);
    },
    updatePassword ({}, {currentPassword, newPassword}) {
        return api.updatePassword(currentPassword, newPassword);
    },
    togglePasswordEditModal({commit, state }) {
        if (!state.passwordEditModal) {
            const passwordEditModal = new window.bootstrap.Modal(document.getElementById('password-edit-modal'), {});
            commit('passwordEditModal', passwordEditModal);
        }
        state.passwordEditModal.toggle();
    },
    toggleUserEditModal({commit, state }) {
        if (!state.userEditModal) {
            const userEditModal = new window.bootstrap.Modal(document.getElementById('user-edit-modal'), {});
            commit('userEditModal', userEditModal);
        }
        state.userEditModal.toggle();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};