<template>
    <div>
        <section class="z-views-wrapper mb-0" v-if="listType === 'cards'">
            <div class="container z-views-container">
                <div class="row g-4" test-id="cards-wrapper">
                    <div v-for="view in views" v-bind:key="view.url"
                        class="col-sm-6 col-md-4">
                        <b-card
                            class="rounded-3"
                            v-on:click="onViewClick(view)"
                            v-bind:img-src="view.previewImage"
                            v-bind:img-alt="view.name"
                            v-bind:class="{ active: currentView && view.url === currentView.url }"
                            v-bind:body-class="'p-0'"
                            img-bottom>
                            <b-card-text class="d-flex p-2 m-0">
                                {{view.name}}
                            </b-card-text>
                            <div class="z-favorite-icon pb-1 pe-1 h4" v-on:click.stop="onFavoriteClick(view)">
                                <b-icon-star-fill v-if="view.isFavorite" v-bind:variant="favoriteIconVariant"></b-icon-star-fill>
                                <b-icon-star v-else v-bind:variant="favoriteIconVariant"></b-icon-star>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </section>

        <nav class="z-views-wrapper" v-if="listType === 'vertical'">
            <ol class="nav flex-column flex-nowrap" v-if="groupBy === 'project' && !loadingProjects">
                <view-list-tree-menu 
                    v-for="node in projectTree"
                    v-bind:key="node.id"
                    :nodes="node.children"
                    :label="node.name"
                    :id="node.id"
                    :url="node.url || false"
                    :is-favorite="node.isFavorite"
                    :is-link="node.project">
                </view-list-tree-menu>
            </ol>
            <ol class="nav flex-column flex-nowrap" v-if="groupBy !== 'project'">
                <li v-for="view in views" v-bind:key="view.url" class="nav-item d-flex flex-row w-100">
                    <a class="nav-link flex-grow-1 align-items-center text-truncate"
                        href="#"
                        v-on:click.prevent="onViewClick(view)"
                        v-bind:class="{ active: currentView && view.url === currentView.url }">{{view.name}}</a>
                    <div class="z-favorite-icon py-1 me-3 h4 text-warning" v-on:click.stop="onFavoriteClick(view)">
                        <b-icon-star-fill v-if="view.isFavorite" v-bind:variant="favoriteIconVariant"></b-icon-star-fill>
                        <b-icon-star v-else v-bind:variant="favoriteIconVariant" class="star"></b-icon-star>
                    </div>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '../store/store';
import { BCard, BCardText, BIconStar, BIconStarFill } from 'bootstrap-vue';
import { projectSort, generateProjectTree } from '../services/util.service';
import ViewListTreeMenu from './ViewListTreeMenu.vue';
/**
 * Component displays list of dashboards.
 * @tag z-view-list
 */
export default {
    name: 'ViewList',
    store,
    props: {
        /**
         * Style of list items.
         * @example list-type="cards"
         * @values cards, vertical
         */
        listType: {
            type: String,
            default: 'cards'
        },
        /**
         * List of tags separated by coma to filter dashboards by. Leave empty to apply tags specified in <a href="#Customizer">Customizer</a>
         * @example tags="tag1,tag2,tag3"
         * @values <any string>
         */
        tags: {
            type: String,
            default: ''
        },
        /**
         * Bootstrap variant styles applies to favorite icon.
         * @example favorite-icon-variant="warning"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        favoriteIconVariant: {
            type: String,
            default: 'warning'
        },
        /**
         * Method for grouping dashboards in the menu. Requires list-type="vertical".
         * @example group-by="project"
         * @values none, project
         */
        groupBy: {
            type: String,
            default: 'none'
        }
    },
    components: {
        BCard,
        BCardText,
        BIconStar,
        BIconStarFill,
        ViewListTreeMenu,
    },
    computed: {
        ...mapState('customizer', {
            configTags: (state) => state.tags,
        }),
        ...mapState('projects', {
            projects: (state) => state.projects,
            loadingProjects: (state) => state.loadingProjects,
        }),
        ...mapState('views', {
            currentView: (state) => state.view,
            views: (state) => state.views,
            viewsPagination: (state) => state.viewsPagination,
            loadingViews: (state) => state.loadingViews,
        }),
        isLastPage() {
            return this.viewsPagination.pageNumber * this.viewsPagination.pageSize >= this.viewsPagination.totalAvailable
        },
        selectedTags() {
            return this.tags && this.tags.length ? this.tags : this.configTags;
        },
        projectTree() {
            // Merge Project and Views so they can be passed into the project tree builder
            let projectsAndViews = [...this.projects, ...this.views];
            let topLevelProjects = this.projects.filter(proj => !proj.parentProjectId).sort(projectSort);
            let projectTree = [];
            topLevelProjects.forEach(topLevelProject => {
                projectTree.push(generateProjectTree(topLevelProject, projectsAndViews));
            });
            return projectTree.filter(d => d.isView || d.children); // Don't show projects without dashboards in them
        }
    },
    mounted() {
        this.scrollContainer = this.$el.parentElement.parentElement;
        this.scrollContainer.addEventListener('scroll', this.scrollWatcher);
    },
    methods: {
        ...mapActions('views', [
            'loadViews',
            'setView',
            'addFavorite',
            'removeFavorite',
        ]),
        ...mapActions('projects', [
            'loadProjects'
        ]),
        ...mapMutations('views', {
            setViewsPagination: 'viewsPagination'
        }),
        onViewClick: function onViewClick(view) {
            this.setView(view);
        },
        onFavoriteClick: function (view) {
            if (view.isFavorite) {
                this.removeFavorite(view);
            } else {
                this.addFavorite(view);
            }
            view.isFavorite = !view.isFavorite;
        },
        fillContainerWithOptions: async function () {
            this.loadViews({tags: this.selectedTags.split(',')})
                .then(() => {
                    if (!this.isLastPage && this.scrollContainer.scrollHeight === this.scrollContainer.clientHeight) {
                        this.setViewsPagination({
                            ...this.viewsPagination,
                            pageNumber: this.viewsPagination.pageNumber + 1,
                        });
                        this.fillContainerWithOptions();
                    } else {
                        if (this.groupBy === 'project') {
                            this.loadProjects();
                        }
                    }
                });
        },
        scrollWatcher() {
            if (this.isLastPage) {
                this.scrollContainer.removeEventListener('scroll', this.scrollWatcher);
                return;
            }

            if (!this.loadingViews && (this.scrollContainer.clientHeight + this.scrollContainer.scrollTop) >= this.scrollContainer.scrollHeight - 100) {
                this.setViewsPagination({
                    ...this.viewsPagination,
                    pageNumber: this.viewsPagination.pageNumber + 1,
                });
                this.loadViews({tags: this.selectedTags.split(',')});
            }
        }
    },
    watch: {
        '$store.state.customizer.configLoaded': function() {
            this.fillContainerWithOptions();
        },
    }
};
</script>

<style>
z-view-list{
    width: 100%;
}
.card-text {
    background-color: var(--bs-light);
}
.card.active {
    border: solid 2px var(--bs-primary);
}
.card.active .card-text {
    background-color: var(--bs-primary);
    color: var(--bs-light);
}
.card-img-overlay {
    padding: 0;
}
.nav-link.active {
    font-weight: bold;
    font-size: 1.1em;
}
.z-favorite-icon {
    cursor: pointer;
}
.card .z-favorite-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
.star {
    visibility: hidden;
}
.nav-item:hover .star {
    visibility: initial;
}
</style>
