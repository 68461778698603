<template>
    <div class="dropzone p-4 d-flex flex-column align-items-center justify-content-center"
        v-on:drop="onLogoDrop"
        v-on:dragenter.prevent
        v-on:dragover.prevent="dropzoneActive = true"
        v-on:dragleave="dropzoneActive = false"
        v-bind:class="{active: dropzoneActive}">
        <span v-if="!uploadedFile && !uploading">Drop a file here to upload</span>
        <span v-if="uploading">Uploading...</span>
        <span v-if="!uploading && uploadedFile">Uploaded {{uploadedFile.name}}</span>
        <span class="text-danger d-block" v-if="error">{{error}}</span>
    </div>
</template>

<script>
import store from '../store/store';
import { upload } from '../services/api.service';
/**
 * Drag'n'Drop area to upload files to local server.
 * @tag z-file-upload
 */
export default {
    name: 'FileUpload',
    store,
    data() {
        return {
            dropzoneActive: false,
            error: null,
            uploading: false,
            uploadedFile: null
        }
    },
    computed: {
    },
    methods: {
        onLogoDrop (event) {
            event.preventDefault();
            this.error = null;
            this.uploading = true;
            let file;
            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < event.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (event.dataTransfer.items[i].kind === 'file') {
                        file = event.dataTransfer.items[i].getAsFile();
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                file = event.dataTransfer.files[0];
            }

            upload(file)
                .then(() => {
                    this.uploadedFile = file;
                    /**
                     * Fired when file have been uploaded.
                     * @property {object} file File model.
                     */
                    this.$emit('uploaded', file);
                })
                .catch(error => {
                    console.error('Error uploading file', error);
                    this.error = `Error : ${error.message || 'File upload failed'}.`;
                })
                .finally(() => {
                    this.uploading = false;
                });
        },
    }
};
</script>

<style>
z-file-upload {
    display: block;
    width: 100%;
    height: 100%;
}
.dropzone {
    border: 1px dashed lightskyblue;
    box-sizing: border-box;
}
.dropzone.active {
    border: 1px dashed DodgerBlue;
    background-color: lightskyblue;
}
</style>
