<template>
    <div class="modal fade" ref="modal" id="user-edit-modal" tabindex="-1" role="dialog" aria-labelledby="userEditModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="userEditModalLabel">Update User</h5>
                    <button v-on:click="onCloseClick()" type="button" class="btn close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                autocomplete="name"
                                class="form-control"
                                id="name"
                                name="name"
                                placeholder="Name"
                                v-model="localName"
                                v-on:keyup="onInputChange()"
                                v-on:change="onInputChange()">
                            <label for="name" class="form-label">Name</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                autocomplete="email"
                                class="form-control"
                                id="email"
                                name="email"
                                placeholder="Email"
                                v-model="localEmail"
                                v-on:keyup="onInputChange()"
                                v-on:change="onInputChange()">
                            <label for="email" class="form-label">Email</label>
                        </div>
                    </form>

                    <div v-if="showError" class="alert alert-warning hidden update-password-error" role="alert">
                        There was an error updating your user.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="onCloseClick()">Cancel</button>
                    <button
                        type="button"
                        id="updateUserSaveButton"
                        class="btn btn-primary"
                        :disabled="submitDisabled"
                        v-on:click="onSaveClick()"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '../store/store';
/**
 * Component renders modal that allow user change account data. Modal might be opened with clicking username in <a href="#NavUserMenu">NavUserMenu</a>
 * @tag z-user-edit-modal
 */
export default {
    name: 'UserEditModal',
    store,
    created () {
        this.userEditModalExists(true);
        this.getUser().then(() => {
            this.localName = this.user.fullname || '';
            this.localEmail = this.user.email || '';
        });
    },
    data: function () {
        return {
            localName: '',
            localEmail: '',
            showError: false,
        };
    },
    computed: {
        ...mapState('user', [ 'user' ]),
        submitDisabled: function () {
            return !this.localName.length || !this.localEmail.length;
        }
    },
    methods: {
        ...mapActions('user', [
            'getUser',
            'updateUser',
            'toggleUserEditModal'
        ]),
        ...mapMutations('user', [
            'userEditModalExists'
        ]),
        onInputChange () {
            this.showError = false;
        },
        onCloseClick: function () {
            this.toggleUserEditModal();
            this.reset();
        },
        onSaveClick () {
            this.showError = false;
            this.updateUser({fullname: this.localName, email: this.localEmail})
                .then(this.onCloseClick)
                .catch(() => {
                    this.showError = true;
                });
        },
        reset () {
            this.userLocal = '';
            this.emailLocal = '';
            this.showError = false;
        }
    }
};
</script>

<style lang="scss">
.user-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, .4);
    transition: all 0.3s;
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .modal {
        display: block;
    }
    .modal-dialog {
        max-width: 500px;
        position: relative;
        margin: 1.75rem auto;
        transition: transform .3s ease-out;
    }
    .modal-content {
        height: auto;
        background: #ffffff;
        pointer-events: all;
    }

    .form-control:focus {
        outline: 0;
    }
    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
