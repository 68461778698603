<template>
    <div class="modal fade" tabindex="-1" id="customizer-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Rapid Portal Customizer</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="onCancelClick()"></button>
                </div>
                <div class="modal-body">

                    <form>

                        <h3>Theme</h3>
                        <hr/>
                        <div class="mb-3 row">
                            <div class="col" test-id="logo">
                                <label class="h6 form-label">Logo</label>
                                <a href v-on:click="onEditLogoClick" test-id="logo-edit-link">
                                    <small class="px-1">Edit</small>
                                </a>
                                <br/>
                                <div v-if="showLogoUploader">
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="selectedLogo" v-on:keydown.enter="onLogoUrlInput" class="form-control" placeholder="Enter image URL" test-id="logo-input" />
                                        <span class="input-group-text z-button-primary" v-on:click="onLogoUrlInput" test-id="logo-apply">Apply</span>
                                    </div>
                                    <div class="text-center mb-3">- OR -</div>
                                    <z-file-upload @uploaded="onLogoFileUpload"></z-file-upload>
                                </div>
                                <img v-bind:src="selectedLogo" v-else-if="selectedLogo" height="45" test-id="logo-img" /><br v-if="!showLogoUploader"/>
                            </div>
                        </div>

                        <div class="mb-3 row">
                            <div class="col" test-id="favicon">
                                <label class="h6 form-label">Favicon</label>
                                <a href v-on:click="onEditFaviconClick" test-id="favicon-edit-link">
                                    <small class="px-1">Edit</small>
                                </a>
                                <br/>
                                <div v-if="showFaviconUploader">
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="selectedFavicon" v-on:keydown.enter="onFaviconUrlInput" class="form-control" placeholder="Enter image URL" test-id="favicon-input">
                                        <span class="input-group-text z-button-primary" v-on:click="onFaviconUrlInput" test-id="favicon-apply">Apply</span>
                                    </div>
                                    <div class="text-center mb-3">- OR -</div>
                                    <z-file-upload @uploaded="onFaviconFileUpload"></z-file-upload>
                                </div>
                                <img v-bind:src="selectedFavicon" v-else-if="selectedFavicon" height="45" test-id="favicon-img" /><br v-if="!showFaviconUploader"/>
                            </div>
                        </div>

                        <div class="mb-3 row">
                            <div class="col" test-id="login-bg">
                                <label class="h6 form-label">Login Background</label>
                                <a href v-on:click="onEditBgClick" test-id="login-bg-edit-link">
                                    <small class="px-1">Edit</small>
                                </a>
                                <br/>
                                <div v-if="showBgUploader">
                                    <div class="input-group mb-3">
                                        <input type="color" id="loginBgColor" class="form-control form-control-color"
                                            v-model="selectedLoginBgColor"
                                            v-on:input="setLoginBgSrc(selectedLoginBgColor)"
                                        />
                                        <input
                                            type="text"
                                            v-model="selectedLoginBgSrc"
                                            v-on:keydown.enter="onLoginBgSrcInput"
                                            class="form-control"
                                            placeholder="Enter image URL"
                                            test-id="login-bg-input"
                                        />
                                        <span class="input-group-text z-button-primary" v-on:click="onLoginBgSrcInput" test-id="login-bg-apply">Apply</span>
                                    </div>
                                    <div class="text-center mb-3">- OR -</div>
                                    <z-file-upload v-if="showBgUploader" @uploaded="onBgFileUpload"></z-file-upload>
                                </div>
                                <div class="z-login-bg-wrapper" v-if="!showBgUploader && selectedLoginBgSrc">
                                    <z-background-visual v-bind:src="selectedLoginBgSrc"></z-background-visual>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <label class="h6 form-label" for="primaryColor">Primary color</label>
                                <input 
                                    type="color" 
                                    id="primaryColor" 
                                    class="form-control form-control-color"
                                    v-model="selectedPrimaryColor"
                                    v-on:input="setPrimaryColor(selectedPrimaryColor)"
                                    test-id="primary-color-input" 
                                />
                            </div>
                            <div class="col">
                                <label class="h6 form-label" for="loginFromColor">Login form background color</label>
                                <input 
                                    type="color" 
                                    id="loginFromColor" 
                                    class="form-control form-control-color"
                                    v-model="selectedLoginFormColor"
                                    v-on:input="setLoginFormColor(selectedLoginFormColor)"
                                    test-id="login-form-color-input"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="h6 form-label" for="secondaryColor">Header icon color</label>
                                <input 
                                    type="color"
                                    id="headerTextColor"
                                    class="form-control form-control-color"
                                    v-model="selectedHeaderIconColor"
                                    v-on:input="setHeaderIconColor(selectedHeaderIconColor)"
                                    test-id="icon-color-input" 
                                />
                            </div>
                            <div class="col">
                                <label class="h6 form-label" for="headerBgColor">Header background color</label>
                                <input 
                                    type="color"
                                    id="headerBgColor"
                                    class="form-control form-control-color"
                                    v-model="selectedHeaderBgColor"
                                    v-on:input="setHeaderBgColor(selectedHeaderBgColor)"
                                    test-id="header-bg-color-input"
                                />
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <label class="h6 form-label" for="dashboardTags">Dashboard Tags</label>
                                <input
                                    type="text"
                                    id="dashboardTags"
                                    v-model="selectedTags"
                                    class="form-control"
                                    test-id="tags-input"
                                />
                                <div class="form-text">Display dashboards with one or more of the chosen tags. Enter tags separated by commas or spaces.</div>
                            </div>
                        </div>

                        <h3>Layout</h3>
                        <hr/>

                        <div class="mb-3 row">
                            <div class="d-flex justify-content-between">
                                <label class="form-label h6">Login Template</label><a class="small" href="/admin/edit/login.html">Advanced</a>
                            </div>
                            <div v-if="loginTemplateError" class="p-3">
                                <div class="alert alert-warning" role="alert">{{loginTemplateError}}</div>
                            </div>
                            <div class="d-flex flex-nowrap overflow-scroll">
                                <span v-for="template in loginTemplates" v-bind:key="template.sha" class="z-screenshot__wrapper pe-3 pb-3 d-inline">
                                    <div class="d-flex flex-column">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="loginTemplate"
                                            v-bind:id="template.sha"
                                            v-bind:value="template"
                                            v-model="selectedLoginTemplate">
                                        <label v-bind:for="template.sha" class="d-flex flex-column">
                                            <img v-bind:src="templateScreenshots[template.name]"
                                                height="180"
                                                class="screenshot"
                                                v-bind:class="{selected: selectedLoginTemplate === template}"
                                                v-if="templateScreenshots[template.name]"
                                                test-id="login-template-img"/>
                                            <div class="missing-image" v-else v-bind:class="{selected: selectedLoginTemplate === template}"
                                                test-id="login-template-missing-img"></div>
                                            <span>{{template.name}}</span>
                                        </label>
                                    </div>
                                </span>
                            </div>

                        </div>

                        <div class="mb-3 row">
                            <div class="d-flex justify-content-between">
                                <label class="form-label h6" for="appTemplate">App Template</label><a class="small" href="/admin/edit/index.html">Advanced</a>
                            </div>
                            <div v-if="appTemplateError" class="p-3">
                                <div class="alert alert-warning" role="alert">{{appTemplateError}}</div>
                            </div>
                            <div class="d-flex flex-nowrap overflow-scroll">
                                <span v-for="template in appTemplates" v-bind:key="template.sha" class="z-screenshot__wrapper pe-3 pb-3 d-inline">
                                    <div class="d-flex flex-column">
                                        <input type="radio" class="btn-check" name="loginTemplate"
                                            v-bind:id="template.sha"
                                            v-bind:value="template"
                                            v-model="selectedAppTemplate"
                                        >
                                        <label v-bind:for="template.sha" class="d-flex flex-column">
                                            <img v-bind:src="templateScreenshots[template.name]"
                                                height="180"
                                                class="screenshot"
                                                v-bind:class="{selected: selectedAppTemplate === template}"
                                                v-if="templateScreenshots[template.name]"
                                                test-id="app-template-img"/>
                                            <div class="missing-image" v-else v-bind:class="{selected: selectedAppTemplate === template}"
                                                test-id="app-template-missing-img"></div>
                                            <span>{{template.name}}</span>
                                        </label>
                                    </div>
                                </span>
                            </div>

                        </div>
                    </form>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="onCancelClick()" test-id="cancel">Close</button>
                    <button type="button" class="btn btn-primary z-button-primary" v-bind:disabled="!isValid" v-on:click="onSaveClick()" test-id="save">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '../store/store';
import zBackgroundVisual from './BackgroundVisual.vue';
import zFileUpload from './FileUpload.vue';
/**
 * Component provides GUI to edit basic appearence of the Rapid Portal.
 * @tag z-customizer
 * 
 */
export default {
    name: 'Customizer',
    store,
    data() {
        return {
            selectedPrimaryColor: '',
            selectedHeaderIconColor: '',
            selectedLoginFormColor: '',
            selectedHeaderBgColor: '',
            selectedLogo: '',
            selectedLoginTemplate: '',
            selectedAppTemplate: '',
            selectedLoginBgSrc: '',
            selectedLoginBgColor:'',
            selectedTags: '',
            showLogoUploader: false,
            showBgUploader: false,
            showFaviconUploader:false
        };
    },
    created () {
        this.getConfig();
    },
    components: {
        zBackgroundVisual,
        zFileUpload
    },
    computed: {
        ...mapState('templates',[
            'appTemplate',
            'appTemplates',
            'loginTemplate',
            'loginTemplates',
            'templateScreenshots'
        ]),
        ...mapState('customizer', [
            'isVisible',
            'logo',
            'favicon',
            'loginBgSrc',
            'primaryColor',
            'headerIconColor',
            'headerBgColor',
            'loginFormColor',
            'tags'
        ]),
        cssVars() {
            return {
                '--primaryColor': this.primaryColor,
                // '--secondaryColor': this.secondaryColor
            }
        },
        appTemplateError() {
            if (!this.appTemplates || !this.appTemplates.length) {
                return 'Error fetching app templates.'
            } else if (!this.selectedAppTemplate) {
                return 'No selected template.'
            } else {
                return false;
            }
        },
        loginTemplateError() {
            if (!this.loginTemplates || !this.loginTemplates.length) {
                return 'Error fetching login templates.'
            } else if (!this.selectedLoginTemplate) {
                return 'No selected template.'
            } else {
                return false;
            }
        },
        isValid() {
            return !this.appTemplateError && !this.loginTemplateError;
        }
    },
    methods: {
        ...mapActions('customizer', ['getConfig', 'saveConfig']),
        ...mapMutations('customizer', {
            setLogo: 'logo',
            setFavicon: 'favicon',
            setPrimaryColor: 'primaryColor',
            setHeaderIconColor: 'headerIconColor',
            setLoginFormColor: 'loginFormColor',
            setHeaderBgColor:'headerBgColor',
            setLoginBgSrc: 'loginBgSrc',
            setTags: 'tags',
        }),

        ...mapMutations('templates', {
            setAppTemplate: 'appTemplate',
            setLoginTemplate: 'loginTemplate',
        }),

        onEditBgClick (event) {
            event.preventDefault();
            this.showBgUploader = !this.showBgUploader;
        },
        onEditLogoClick (event) {
            event.preventDefault();
            this.showLogoUploader = !this.showLogoUploader;
        },
        onEditFaviconClick (event) {
            event.preventDefault();
            this.showFaviconUploader = !this.showFaviconUploader;
        },
        onBgFileUpload (event) {
            this.selectedLoginBgSrc = '/assets/' + event.name;
            this.setLoginBgSrc(this.selectedLoginBgSrc);
            this.selectedLoginBgColor = null;
            this.showBgUploader = !this.showBgUploader;
        },
        onLoginBgSrcInput () {
            this.setLoginBgSrc(this.selectedLoginBgSrc);
            this.selectedLoginBgColor = this.selectedLoginBgSrc;
            this.showBgUploader = !this.showBgUploader;
        },
        onLogoFileUpload (event) {
            this.selectedLogo = '/assets/' + event.name;
            this.setLogo(this.selectedLogo);
            this.showLogoUploader = false;
        },
        onLogoUrlInput () {
            this.setLogo(this.selectedLogo);
            this.showLogoUploader = false;
        },
        onFaviconUrlInput() {
            this.setFavicon(this.selectedFavicon);
            this.showFaviconUploader = false;
        },
        onFaviconFileUpload (event) {
            this.selectedFavicon = '/assets/' + event.name;
            this.setFavicon(this.selectedFavicon);
            this.showFaviconUploader = false;
        },
        onSaveClick () {
            this.setAppTemplate(this.selectedAppTemplate);
            this.setLoginTemplate(this.selectedLoginTemplate);
            this.setPrimaryColor(this.selectedPrimaryColor);
            this.setHeaderIconColor(this.selectedHeaderIconColor);
            this.setLoginFormColor(this.selectedLoginFormColor);
            this.setLogo(this.selectedLogo);
            this.setFavicon(this.selectedFavicon);
            this.setTags(this.selectedTags);
            this.saveConfig()
                .then(() => {
                    window.location.reload();
                });
        },
        onCancelClick () {
            window.location.reload();
        },
        applyFavicon() {
            let link = document.querySelector('link[rel*="icon"]');
            if (this.favicon) {
                let linkEl = link || document.createElement('link');
                linkEl.type = 'image/x-icon';
                linkEl.rel = 'shortcut icon';
                linkEl.href = this.favicon;
                document.head.appendChild(linkEl);
            }
        },
    },
    watch: {
        '$store.state.customizer.logo': function() {
            this.selectedLogo = this.logo;
        },
        '$store.state.customizer.primaryColor': function() {
            const docRoot = document.documentElement;
            docRoot.style.setProperty('--bs-primary', this.primaryColor);
            this.selectedPrimaryColor = this.primaryColor;
        },
        '$store.state.customizer.headerIconColor': function() {
            const docRoot = document.documentElement;
            docRoot.style.setProperty('--z-header-icon-color', this.headerIconColor);
            this.selectedHeaderIconColor = this.headerIconColor;
        },
        '$store.state.customizer.headerBgColor': function() {
            const docRoot = document.documentElement;
            docRoot.style.setProperty('--z-header-bg-color', this.headerBgColor);
            this.selectedHeaderBgColor = this.headerBgColor;
        },
        '$store.state.customizer.loginFormColor': function() {
            const docRoot = document.documentElement;
            docRoot.style.setProperty('--z-login-form-color', this.loginFormColor);
            this.selectedLoginFormColor = this.loginFormColor;
        },
        '$store.state.customizer.loginBgSrc': function() {
            this.selectedLoginBgSrc = this.loginBgSrc;
        },
        '$store.state.customizer.tags': function() {
            this.selectedTags = this.tags;
        },
        '$store.state.customizer.favicon': function () {
            this.applyFavicon();
            this.selectedFavicon = this.favicon;
        },
        '$store.state.templates.appTemplate': function() {
            this.selectedAppTemplate = this.appTemplate;
        },
        '$store.state.templates.loginTemplate': function() {
            this.selectedLoginTemplate = this.loginTemplate;
        }
    }
};
</script>

<style>
#z-customizer {
    z-index: 100;
}
.z-screenshot__wrapper:nth-child(3n) {
    padding-right: 0 !important;
}
.screenshot,
.missing-image {
    border: solid 1px lightgray;
    border-radius: 3px;
}
.screenshot.selected,
.missing-image.selected {
    border: solid 2px blue;
}
.missing-image {
    width: 240px;
    height: 180px;
    background-color: lightgray;
}
.z-login-bg-wrapper {
    position: relative;
    height: 240px;
    width: 100%;
}
.z-nav-item .b-icon {
    font-size: 1.2em;
}
/* Some colors are overriden here so they affect bootstrap elements not in our Vue components.
 * Components that use BS classes override the colors in their own Vue files.
*/
.bg-primary {
    background-color: var(--bs-primary) !important;
}
.z-nav-item .btn-primary {
    background-color: var(--z-header-bg-color) !important;
    border-color: var(--z-header-bg-color) !important;
}
.dropdown-item:active {
    background-color: var(--bs-primary) !important;
}
.z-header-bg{
    background-color: var(--z-header-bg-color) !important;
}
.z-button-primary {
    background-color: #007bff !important;
    border-color: #007bff !important;
    color: #fff !important;
}
.z-login-form{
    background-color: var(--z-login-form-color);
}
.z-nav-item,
.z-nav-item.b-dropdown {
    stroke: var(--z-header-icon-color) !important;
    color: var(--z-header-icon-color) !important;
}
.z-nav-item .dropdown-toggle::after,
.z-nav-item .dropdown .dropdown-item:active {
    color: var(--z-header-icon-color) !important;
}
.card.active .card-text {
    color: var(--z-header-icon-color) !important;
}
z-login-submit-button button {
    color: var(--z-header-icon-color) !important;
}
</style>
