<template>
    <div v-if="error">
        <p class="text-danger fw-lighter">{{error}}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '../store/store';
/**
 * Component displays error on login page.
 * @tag z-login-error
 */
export default {
    name: 'LoginError',
    store,
    computed: {
        ...mapState('login', [ 'error' ])
    }
};
</script>

<style>
</style>
