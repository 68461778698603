<template>
    <div class="z-nav-item">
        <b-dropdown v-b-tooltip.hover="{customClass: 'opacity-100' }" title="User Menu" right v-bind:variant="variant">
            <template #button-content>
                <b-icon-person data-toggle="dropdown"></b-icon-person>
            </template>

            <b-dropdown-header v-if="userEditModalExists" v-on:click="toggleUserEditModal()" class="pointer" test-id="user-link">{{username}}</b-dropdown-header>
            <b-dropdown-header v-else test-id="user-label">{{username}}</b-dropdown-header>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-if="showUsersLink" v-on:click="onAdminClick()" test-id="admin">Admin</b-dropdown-item>
            <b-dropdown-item v-if="showCustomizerLink" v-on:click="toggleCustomizer()" test-id="customizer">Customize</b-dropdown-item>
            <b-dropdown-item v-if="showChangePasswordLink" v-on:click="togglePasswordEditModal()" test-id="password">Change Password</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-on:click="onLogoutClick()" test-id="logout">Logout</b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import 'apitoolbox-ui/user-edit-modal.js';
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
import { BDropdown, BDropdownHeader, BDropdownDivider, BDropdownItem, BIconPerson } from 'bootstrap-vue';
/**
 * Component displays button with dropdown that provides list users action. <br>
 * <strong>Note!</strong> Some options require user to be an Admin or some specific component to be presented on the same page.
 * @tag z-nav-user-menu
 */
export default {
    name:'NavUserMenu',
    store,
    components: {
        BDropdown,
        BDropdownDivider,
        BDropdownItem,
        BDropdownHeader,
        BIconPerson,
    },
    created () {
        this.loadAbout();
    },
    props: {
        /**
         * Bootstrap button variant.
         * @example variant="primary"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        variant: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        ...mapState('user', [
            'passwordEditModalExists', 
            'userEditModalExists'
        ]),
        ...mapState(['about']),
        username: function () {
            return this.about && this.about.payload ? this.about.payload.username : '';
        },
        showChangePasswordLink: function () {
            return this.passwordEditModalExists && this.about && this.about.tableau_server.has_internal_server_admin;
        },
        showCustomizerLink: function () {
            return !!document.querySelector('z-customizer') && this.isAdmin();
        },
        showUsersLink: function () {
            return this.isAdmin();
        }
    },
    methods: {
        ...mapActions('customizer', [
            'toggleCustomizer'
        ]),
        ...mapActions('login', [
            'logout'
        ]),
        ...mapActions('user',[
            'toggleUserEditModal',
            'togglePasswordEditModal',
        ]),
        ...mapActions([
            'loadAbout',
        ]),
        onAdminClick () {
            window.location = '/admin/edit';
        },
        onLogoutClick () {
            this.logout();
        },
        isAdmin () {
            return this.about && this.about.payload.admin != 'false';
        }
    },
};
</script>

<style>
.b-dropdown-text {
     margin-bottom: 0;
}
.pointer > * {
    cursor: pointer;
}
.opacity-100{
    opacity: 1;
}
</style>
