<template>
    <nav class="z-views-wrapper">
        <ol class="nav flex-column flex-nowrap" v-if="groupBy === 'project' && !loadingProjects">
            <workbook-list-tree-menu 
                v-for="node in projectTree"
                v-bind:key="node.id"
                :nodes="node.children"
                :label="node.name"
                :id="node.id"
                :url="node.url || false"
                :is-favorite="node.isFavorite"
                :is-link="!!node.project">
            </workbook-list-tree-menu>
        </ol>
        <ol class="nav flex-column flex-nowrap" v-if="groupBy !== 'project'">
            <li v-for="workbook in workbooks" v-bind:key="workbook.id" class="nav-item d-flex flex-row w-100">
                <a class="nav-link flex-grow-1 align-items-center text-truncate"
                    href="#"
                    v-on:click.prevent="onWorkbookClick(workbook)"
                    v-bind:class="{ active: currentWorkbook && currentWorkbook.id === workbook.id }">{{workbook.name}}</a>
                <div class="z-favorite-icon py-1 me-3 h4 text-warning" v-on:click.stop="onFavoriteClick(workbook)">
                    <b-icon-star-fill v-if="workbook.isFavorite" v-bind:variant="favoriteIconVariant"></b-icon-star-fill>
                    <b-icon-star v-else v-bind:variant="favoriteIconVariant" class="star"></b-icon-star>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '../store/store';
import { BIconStar, BIconStarFill } from 'bootstrap-vue';

import { projectSort, generateProjectTree } from '../services/util.service';
import WorkbookListTreeMenu  from './WorkbookListTreeMenu.vue';

/**
 * Component displays list of dashboards.
 * Note: In order to work properly workbooks should have `showTabs` property to be enabled. 
 * @tag z-workbook-list
 */
export default {
    name: 'WorkbookList',
    store,
    props: {
        /**
         * List of tags separated by coma to filter dashboards by. Leave empty to apply tags specified in <a href="#Customizer">Customizer</a>
         * @example tags="tag1,tag2,tag3"
         * @values <any string>
         */
        tags: {
            type: String,
            default: ''
        },
        /**
         * Bootstrap variant styles applies to favorite icon.
         * @example favorite-icon-variant="warning"
         * @values primary, secondary, success, danger, warning, info, light, dark, link
         */
        favoriteIconVariant: {
            type: String,
            default: 'warning'
        },
        /**
         * Method for grouping workbooks in the menu.
         * @example group-by="project"
         * @values none, project
         */
         groupBy: {
            type: String,
            default: 'none'
        }
    },
    components: {
        WorkbookListTreeMenu,
        BIconStar,
        BIconStarFill
    },
    computed: {
        ...mapState('customizer', {
            configTags: (state) => state.tags,
        }),
        ...mapState('projects', {
            projects: (state) => state.projects,
            loadingProjects: (state) => state.loadingProjects,
        }),
        ...mapState('workbooks', {
            currentWorkbook: (state) => state.workbook,
            workbooks: (state) => state.workbooks,
            workbookPagination: (state) => state.pagination
        }),
        isLastPage () {
            const { pageNumber, pageSize, totalAvailable } = this.workbookPagination;
            return pageNumber * pageSize >= totalAvailable;
        },
        selectedTags () {
            return this.tags?.length ? this.tags : this.configTags;
        },
        projectTree() {
            // Merge Project and Views so they can be passed into the project tree builder
            let projectsAndWorkbooks = [...this.projects, ...this.workbooks];
            let topLevelProjects = this.projects.filter(proj => !proj.parentProjectId).sort(projectSort);
            let projectTree = [];
            topLevelProjects.forEach(topLevelProject => {
                projectTree.push(generateProjectTree(topLevelProject, projectsAndWorkbooks));
            });
            return projectTree.filter(d => d.isWorkbook || d.children); // Don't show projects without dashboards in them
        }
    },
    methods: {
        ...mapActions('workbooks', [
            'loadWorkbooks',
            'setWorkbook',
            'addFavorite',
            'removeFavorite'
        ]),
        ...mapActions('projects', [
            'loadProjects'
        ]),
        ...mapMutations('workbooks', {
            setPagination: 'setPagination'
        }),
        onWorkbookClick (workbook) {
            this.setWorkbook({workbook});
        },
        onFavoriteClick: function (workbook) {
            if (workbook.isFavorite) {
                this.removeFavorite(workbook);
            } else {
                this.addFavorite(workbook);
            }
            workbook.isFavorite = !workbook.isFavorite;
        },
        async loadAllWorkbooks() {
            do {
                await this.loadWorkbooks({tags: this.selectedTags.split(',')});
            } while (!this.isLastPage);
            this.loadProjects();
        }
    },
    watch: {
        '$store.state.customizer.configLoaded': function () {
            this.loadAllWorkbooks();
        },
    }
};
</script>

<style>
z-workbook-list{
    width: 100%;
}
.card-text {
    background-color: var(--bs-light);
}

.card.active {
    border: solid 2px var(--bs-primary);
}

.card.active .card-text {
    background-color: var(--bs-primary);
    color: var(--bs-light);
}

.card-img-overlay {
    padding: 0;
}

.nav-link.active {
    font-weight: bold;
    font-size: 1.1em;
}

.z-favorite-icon {
    cursor: pointer;
}

.card .z-favorite-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.star {
    visibility: hidden;
}

.nav-item:hover .star {
    visibility: initial;
}
.z-dropdown .dropdown-toggle::after{
    display: none;
}
</style>
