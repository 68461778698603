<template>
    <input
        type="password"
        class="form-control"
        name="password"
        v-bind:placeholder="label"
        v-bind:class="myClass"
        v-model="password"
        v-on:input="onValueChange"
        v-on:keyup.enter="login"
        required="required"
    />
</template>

<script>
import store from '../store/store';
import { mapActions, mapMutations } from 'vuex';
/**
 * Component displays input that allow user to enter password.
 * @tag z-login-password-input
 */
export default {
    name: 'LoginPasswordInput',
    store,
    props: {
        /**
         * Makes input required.
         * @example required
         */
        required: {
            type: Boolean,
            default: true
        },
        /**
         * Text will be displayed as input's placeholder.
         * @example label="Example"
         */
        label: {
            type: String,
            default: 'Password'
        },
        /**
         * Adds class to input element.
         * @example my-class="Example"
         */
        myClass: {
            type: String
        }
    },
    data: function () {
        return {
            password: ''
        };
    },
    created() {
        if (this.required) {
            store.commit('login/required', 'password');
        }
    },
    methods: {
        ...mapActions('login', [ 'login' ]),
        ...mapMutations('login', {
            setLoginError:'error',
            setPassword:'password',
        }),
        onValueChange: function () {
            this.setLoginError(null);
            this.setPassword(this.password);
        }
    }
};
</script>

<style>
</style>
