<template>
    <button
        type="submit"
        class="btn btn-primary btn-block"
        v-bind:style="{backgroundColor: primaryColor, borderColor: primaryColor, color: textColor}"
        v-bind:class="myClass"
        :disabled="isLoggingIn"
        v-on:click="login()">{{buttonText}}</button>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '../store/store';
/**
 * Component sends login request.
 * @tag z-login-submit-button
 */
export default {
    name: 'LoginSubmitButton',
    store,
    computed: {
        ...mapState('customizer',[ 'primaryColor' ]),
        ...mapState('login',[ 'isLoggingIn' ])
    },
    methods: {
        ...mapActions('login', [ 'login' ])
    },
    props: {
        /**
         * Text will be displied on the button.
         * @example button-text="Sign In"
         * @default 
         */
        buttonText:{
            type:String,
            default:'Log in'
        },
        /**
         * Color of the text.
         * @example text-color="#FFFFFF"
         */
        textColor:{
            type:String,
            default:'black'
        },
        /**
         * Adds class to button element.
         * @example my-class="Example"
         */
        myClass:{
            type:String
        },
    }
};
</script>

<style>
</style>
