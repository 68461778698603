import * as api from '../../services/api.service';

const state = () => ({
    modal: null,
    isVisible: false,
    primaryColor: '',
    headerIconColor: '',
    loginFormColor: '',
    headerBgColor: '',
    loginBgSrc: null,
    logo: '',
    favicon: '',
    tags: '',
    configLoaded: false
});

const mutations = {
    modal (state, modal) {
        state.modal = modal;
    },
    isVisible (state, isVisible) {
        state.isVisible = isVisible;
    },
    primaryColor (state, color) {
        state.primaryColor = color;
    },
    loginFormColor (state, color) {
        state.loginFormColor = color;
    },
    headerIconColor (state, color) {
        state.headerIconColor = color;
    },
    headerBgColor (state, color) {
        state.headerBgColor = color;
    },
    loginBgSrc (state, src) {
        state.loginBgSrc = src;
    },
    logo (state, logo) {
        state.logo = logo;
    },
    favicon (state, favicon) {
        state.favicon = favicon;
    },
    tags (state, tags) {
        state.tags = tags;
    },
    configLoaded(state, loaded) {
        state.configLoaded = loaded;
    },
};

const getters = {};

const actions = {
    toggleCustomizer({ commit, state }) {
        if (!state.modal) {
            const customizerModal = new window.bootstrap.Modal(document.getElementById('customizer-modal'), {
                backdrop:'static'
            });
            commit('modal', customizerModal);
        }
        state.modal.toggle();
    },
    async saveConfig({ state, rootState }) {
        const config = await api.getConfig();
        Object.assign(config, {
            logo: state.logo,
            favicon: state.favicon,
            primaryColor: state.primaryColor,
            headerIconColor: state.headerIconColor,
            loginFormColor: state.loginFormColor,
            headerBgColor: state.headerBgColor,
            loginBgSrc: state.loginBgSrc,
            tags: state.tags,
            appTemplate: rootState.templates.appTemplate.name,
            loginTemplate: rootState.templates.loginTemplate.name,
        });
        await api.saveConfig(config);

        if (rootState.templates.appTemplate.name !== 'custom') {
            const appTemplate = await api.getTemplate(rootState.templates.appTemplate.download_url);
            await api.saveTemplate(appTemplate, 'index.html');
        }

        if (rootState.templates.loginTemplate.name !== 'custom') {
            const loginTemplate = await api.getTemplate(rootState.templates.loginTemplate.download_url);
            await api.saveTemplate(loginTemplate, 'login.html');
        }

        return config;
    },
    async getConfig ({dispatch, rootState, commit}) {
        let config = await api.getConfig();

        commit('logo', config.logo);
        commit('favicon', config.favicon);
        commit('primaryColor', config.primaryColor);
        commit('headerIconColor', config.headerIconColor);
        commit('loginFormColor', config.loginFormColor);
        commit('headerBgColor', config.headerBgColor);
        commit('loginBgSrc', config.loginBgSrc);
        commit('tags', config.tags);

        commit('templates/templateRepo', config.templateRepo, {root:true});
        commit('templates/templateBranch', config.templateBranch, {root:true});

        await dispatch('templates/loadAppTemplates', null, {root:true})

        let selectedAppTemplate =
            rootState.templates.appTemplates.find(temp => temp.name === 'custom') ||
            rootState.templates.appTemplates.find(temp => temp.name === config.appTemplate);

        commit('templates/appTemplate', selectedAppTemplate, {root:true});

        await dispatch('templates/loadLoginTemplates', null, {root:true})

        let selectedLoginTemplate =
            rootState.templates.loginTemplates.find(temp => temp.name === 'custom') ||
            rootState.templates.loginTemplates.find(temp => temp.name === config.loginTemplate);

        commit('templates/loginTemplate', selectedLoginTemplate, {root:true});

        await dispatch('templates/loadTemplateScreenshots', null, {root:true});
        
        commit('configLoaded', true);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};