import * as api from '../../services/api.service';
import * as util from '../../services/util.service';

const state = () => ({
    loading: false,
    workbook: null, // Selected workbook
    workbooks: [], // List of all workbooks
    pagination: {
        pageNumber: 1,
        pageSize: 99
    },
});

const mutations = {
    setLoading (state, isLoading) {
        state.loading = isLoading;
    },
    setWorkbooks (state, workbooks) {
        state.workbooks = workbooks;
        state.workbooks.sort(util.viewSort);
    },
    setWorkbook (state, workbook) {
        state.workbook = workbook;
    },
    setPagination (state, { pageNumber, pageSize, totalAvailable }) {
        state.pagination = {
            pageNumber: parseInt(pageNumber, 10),
            pageSize: parseInt(pageSize, 10),
            totalAvailable: parseInt(totalAvailable, 10)
        };
    },
};

const getters = {};

const actions = {
    async loadWorkbooks ({ commit, state }, { tags }) {
        commit('setLoading', true);
        const { pageNumber, pageSize } = state.pagination;
        const { workbooks, pagination } = await api.getWorkbooks({ tags }, pageNumber, pageSize);
        let newWorkbooks = [...state.workbooks, ...workbooks];
        util.removeDuplicates(newWorkbooks);
        commit('setWorkbooks', newWorkbooks);
        commit('setPagination', pagination);
        commit('setLoading', false);
    },
    async setWorkbook ({ commit, dispatch }, { workbook, setView = true }) {
        let views = await api.getViewsForWorkbook({ workbook });
        workbook.views = views;
        commit('setWorkbook', workbook);
        if (setView) {
            dispatch('views/setView', workbook.views[0], { root: true });
        }
    },
    addFavorite ({ }, workbook) {
        api.addWorkbookToFavorite({ workbook });
    },
    removeFavorite ({ }, workbook) {
        api.deleteWorkbookFromFavorite({ workbook });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};