<template>
    <input
        type="text"
        class="form-control"
        name="tableau_server_site"
        v-model="site"
        v-on:input="onValueChange($event)"
        v-on:keyup.enter="login"
        v-bind:placeholder="label"
        v-bind:class="myClass" />
</template>

<script>
import store from '../store/store';
import { mapActions, mapMutations } from 'vuex';
/**
 * Component displays input that allow user to enter Tableau's Server Site.
 * @tag z-login-tableau-server-site-input
 */
export default {
    name: 'LoginTableauServerSiteInput',
    store,
    props: {
        /**
         * Makes input required.
         * @example required
         */
        required: {
            type: Boolean,
            default: false
        },
        /**
         * Text will be displayed as input's placeholder.
         * @example label="Example"
         */
        label: {
            type: String,
            default: 'Tableau Site'
        },
        /**
         * Adds class to input element.
         * @example my-class="Example"
         */
        myClass: {
            type: String
        }
    },
    data: function () {
        return {
            site: ''
        };
    },
    created() {
        if (this.required) {
            store.commit('login/required', 'tableauServerSite');
        }
    },
    methods: {
        ...mapActions('login', [ 'login' ]),
        ...mapMutations('login', {
            setLoginError:'error',
            setTableauServerSite:'tableauServerSite',
        }),
        onValueChange: function () {
            this.setLoginError(null);
            this.setTableauServerSite(this.site);
        }
    }
};
</script>

<style>
</style>
